<template>
  <span
    class="note content"
    @click="expand"
    :id="id"
    :class="[
      { 'is-expanded': isExpanded },
      { 'is-collapsable note--Important': isCollapsable },
    ]"
    ref="noteBox"
    ><slot
  /></span>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default() {
        return ''
      },
    },
    collapsable: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isExpanded: false,
      height: null,
    }
  },
  methods: {
    expand() {
      this.isExpanded = !this.isExpanded
    },
  },
  mounted() {
    this.height = this.$refs.noteBox.clientHeight
  },
  computed: {
    isCollapsable() {
      return this.collapsable
    },
  },
}
</script>

<style>
.note {
  margin-bottom: 20px;
  display: block;
}

.note:last-child {
  margin-bottom: 0;
}

.note.is-collapsable:hover {
  opacity: 0.8;
}

.note.is-collapsable::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  background: linear-gradient(to top, #000000 10%, transparent);
  color: var(--bg);
}

.note--Important {
  background: #000000;
  color: var(--bg);
  padding: 20px;
  border: 1px solid #6d6d6d;
  display: block;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.note.is-collapsable {
  max-height: 80px;
  cursor: pointer;
}

.note--Small {
  font-size: 14px;
}

.note.is-expanded {
  max-height: none;
}

.note.is-expanded::before {
  display: none;
}
</style>

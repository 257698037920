<template>
  <div class="buttons">
    <button @click="csvDownload(data)">Download CSV</button>
    <button @click="jsonDownload(data)">Download JSON</button>
  </div>
  <div class="content">
    <table class="table">
      <tr class="sticky">
        <th
          v-for="language in ['English', 'Spanish', 'French']"
          :key="language"
        >
          {{ language }}
        </th>
      </tr>
      <tr v-for="(shape, shapeIndex) in data" :key="shape.id">
        <td
          v-for="translationIndex in Object.keys(shape.content)"
          :key="translationIndex"
        >
          <div class="editor">
            <html-editor
              v-model="data[shapeIndex].content[translationIndex]"
              :editable="translationIndex !== 'en'"
            ></html-editor>
            <button
              class="fetchTranslationButton"
              @click="
                fetchTranslation(data[shapeIndex].content, translationIndex)
              "
            >
              Fetch translation
            </button>
          </div>
        </td>
      </tr>
    </table>

    <div class="freeTranslation">
      <h2>Free translations (does not save)</h2>
      <textarea v-model="translations.text.en"></textarea>
      <textarea v-model="translations.text.es"></textarea>
      <textarea v-model="translations.text.fr"></textarea>
      <select name="" id="" v-model="translations.language">
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
      </select>
      <button
        @click="fetchTranslation(translations.text, translations.language)"
      >
        Translate
      </button>
    </div>
  </div>
</template>

<style>
.buttons {
  display: flex;
  padding: 20px;
  position: sticky;
  z-index: 20;
  top: 0;
  background: linear-gradient(white 80%, transparent);
}

.buttons button {
  all: unset;
  padding: 10px 20px;
  background: var(--accent);
  color: var(--bg);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  margin-right: 20px;
  cursor: pointer;
}

.buttons button:hover {
  background: var(--fg);
}

.table {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.table tr {
  display: flex;
}

.table td,
.table th {
  flex: 1;
  text-align: left;
  padding: 5px;
  background: #ddd;
}

.table td {
  display: flex;
  flex-direction: column;
}

.editor {
  height: 100%;
  overflow: hidden;
  background: white;
  height: 300px;
  overflow-y: auto;
}

.fetchTranslationButton {
  all: unset;
  padding: 5px 10px;
  color: var(--accent);
  background: #eee;
  margin: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  margin-top: 20px;
}

.ProseMirror {
  padding: 0 10px;
}

.sticky {
  position: sticky;
  top: 80px;
  z-index: 10;
}

.sticky th {
  padding: 10px;
  background: black;
  color: white;
}

.freeTranslation {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 20px;
}

.freeTranslation textarea {
  flex: 1;
  margin-bottom: 20px;
  border: 1px solid black;
}

.freeTranslation select {
  border: 1px solid black;
  margin-bottom: 20px;
  background: white;
  padding: 10px;
}

.freeTranslation button {
  all: unset;
  padding: 10px 20px;
  background: var(--accent);
  color: var(--bg);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
</style>

<script>
import HtmlEditor from '@/components/HtmlEditor.vue'
import data from '@/data.json'
import translations from '@/translations.json'
import csvDownload from 'json-to-csv-export'

export default {
  components: {
    HtmlEditor,
  },
  mounted() {
    const storageItem = localStorage.getItem(
      '_data_rights_survey_translation_content'
    )
    if (storageItem && JSON.parse(storageItem)) {
      this.data = JSON.parse(storageItem)
    } else {
      localStorage.removeItem('_data_rights_survey_translation_content')
    }
  },
  watch: {
    data: {
      handler() {
        localStorage.setItem(
          '_data_rights_survey_translation_content',
          JSON.stringify(this.data)
        )
      },
      deep: true,
    },
  },
  data() {
    return {
      csv: {},
      translations: {
        text: {
          en: '',
        },
        language: 'en',
      },
      data: data
        .filter((i) => i.content)
        .map((i) => {
          const { id, content } = i
          return {
            id,
            content: {
              en: content,
              es: this.getTranslationById(id, 'es'),
              fr: this.getTranslationById(id, 'fr'),
            },
          }
        }),
    }
  },
  methods: {
    fetchTranslation(content, language) {
      confirm(
        'This will overwrite the current translation in this field. Continue?'
      ) &&
        fetch('/v2/translate', {
          method: 'POST',
          headers: {
            Host: 'api.deepl.com',
            Authorization:
              'DeepL-Auth-Key d81e6d2f-afa9-b34a-cdb4-bc5fb35ec079',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `text=${encodeURIComponent(
            content.en
          )}&target_lang=${language.toUpperCase()}`,
        })
          .then((response) => response.json())
          .then((data) => {
            content[language] = data.translations[0].text
          })
    },
    csvDownload(data) {
      const dataToConvert = {
        data: [...data],
        filename: 'data_rights_survey_translations',
        headers: ['ID', 'Content'],
      }
      csvDownload(dataToConvert)
    },
    handleTranslation(translation, language, id) {
      this.data.find((s) => s.id === id).content[language] = translation
    },
    getTranslationById(id, language) {
      return translations.find((s) => s.id === id).content[language]
    },
    jsonDownload(data) {
      var dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(data))
      var downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute(
        'download',
        'data_rights_survey_translations' + '.json'
      )
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },
  },
}
</script>

<template>
  <div class="wrapper">
    <nav class="navigation">
      <h1 class="logo">
        <a href="http://publicservices.international">
          <img src="@/assets/logo.svg" alt="" />
          <span>Public Services International</span>
        </a>
      </h1>
      <ul class="languages">
        <li>
          <router-link
            :to="{ hash: $route.hash, query: {} }"
            :class="{ active: !$route.query.language }"
            >EN</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ hash: $route.hash, query: { language: 'es' } }"
            :class="{ active: $route.query.language === 'es' }"
            >ES</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ hash: $route.hash, query: { language: 'fr' } }"
            :class="{ active: $route.query.language === 'fr' }"
            >FR</router-link
          >
        </li>
      </ul>
      <ul class="navigation__List">
        <li
          v-for="(n, i) in navigationItems"
          :key="`nav-${n}`"
          class="navigation__ListItem"
          :class="{
            'is-active': isActiveNavItem(n),
          }"
        >
          <a
            :href="`#${n.next[0].id}`"
            v-html="findString(n.content)"
            v-if="
              navigationItems.findIndex((item) => isActiveNavItem(item)) >= i
            "
          />
          <span v-html="findString(n.content)" v-else />
        </li>
      </ul>
    </nav>

    <ul class="slides">
      <li :key="`slide-${currentSlide}`" :id="currentSlide.id" class="slide">
        <template v-if="getPrevious(currentSlide)">
          <router-link
            :to="{ hash: getPrevious(currentSlide), query: $route.query }"
            class="cta__Back"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M136.97 380.485l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113l83.928-83.444c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-116.485 116c-4.686 4.686-4.686 12.284 0 16.971l116.485 116c4.686 4.686 12.284 4.686 16.97-.001z"
              />
            </svg>
          </router-link>
        </template>
        <div
          class="answer content"
          v-html="getContent(getShape(currentSlide?.start?.item))"
        />
        <div
          class="content"
          :class="{ selectedAnswer: displayYesOrNo(currentSlide) }"
          v-html="getContent(getShape(currentSlide?.end?.item))"
        />
        <img
          v-for="image in getImage(currentSlide)"
          :src="`/img/data-images/${image}.png`"
          :key="image"
          class="image"
        />
        <ul class="cta">
          <li
            v-if="currentSlide.id === '3458764528714317110'"
            class="cta__Item"
          >
            <a class="cta__Button" :href="`#3458764530057327207`">Next</a>
          </li>
          <li
            class="cta__Item"
            v-for="next in getNext(currentSlide)"
            :key="next.id"
          >
            <template v-if="displayYesOrNo(next)">
              <a class="cta__Button" :href="`#${getNext(next)[0].id}`">{{
                findString(stripTags(getShape(next?.end?.item)?.content))
              }}</a>
            </template>
            <template v-else>
              <select
                class="select"
                id="countryPicker"
                v-if="
                  getShape(next?.end?.item)?.shape === 'left_right_arrow' &&
                  getShape(next?.end?.item)?.style?.fillColor === '#e69fbf'
                "
                v-model="selectedCountry"
                @change="handleCountrySelect(selectedCountry)"
              >
                <option :value="null">
                  {{ findString(stripTags(getShape(next?.end?.item).content)) }}
                </option>
                <option
                  :value="country"
                  v-for="country in sortedCountries"
                  :key="country.iso"
                >
                  {{ country.name }}
                </option>
              </select>
              <template v-else>
                <NoteAccordion
                  :id="`note-${next?.end?.item}`"
                  class="note--Important"
                  v-if="
                    getShape(next?.end?.item)?.style.fillColor === '#cee741'
                  "
                  ><span v-html="getContent(getShape(next?.end?.item))"
                /></NoteAccordion>
                <router-link
                  class="cta__Button"
                  :to="{
                    hash: `#${next.id}`,
                    query: {
                      language: $route.query.language,
                    },
                  }"
                  v-if="getNext(next).length > 0"
                  >{{ findString('Next') }}</router-link
                >
              </template>
            </template>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import data from '@/data.json'
import translations from '@/translations.json'
import countries from '@/countries.json'
import NoteAccordion from '@/components/NoteAccordion'

export default {
  name: 'HomeView',
  components: {
    NoteAccordion,
  },
  data() {
    return {
      data,
      translations,
      countries,
      selectedCountry: null,
      state: {
        to: null,
        from: null,
        path: [],
      },
      strings: [
        {
          en: 'Next',
          es: 'Siguiente',
          fr: 'Suivant',
        },
        {
          en: 'Back',
          es: 'Atrás',
          fr: 'Retour',
        },
        {
          en: 'Introduction',
          es: 'Introducción',
          fr: 'Introduction',
        },
        {
          en: 'Part 1 - Getting Started',
          es: 'Parte 1 - Empezando',
          fr: 'Partie 1 - Commencer',
        },
        {
          en: 'Part 2 - Data collection, sources and clear limits',
          es: 'Parte 2 - Recolección de datos, fuentes y límites claros',
          fr: 'Partie 2 - Collecte de données, sources et limites claires',
        },
        {
          en: 'Part 3 - Data Analysis',
          es: 'Parte 3 - Análisis de datos',
          fr: 'Partie 3 - Analyse des données',
        },
        {
          en: 'Part 4 - 3rd Party Access and Control',
          es: 'Parte 4 - Acceso y control de terceros',
          fr: 'Partie 4 - Accès et contrôle tiers',
        },
        {
          en: 'Conclusion',
          es: 'Conclusión',
          fr: 'Conclusion',
        },
        {
          en: 'Pick your country',
          es: 'Elige tu país',
          fr: 'Choisissez votre pays',
        },
        {
          en: 'Yes',
          es: 'Sí',
          fr: 'Oui',
        },
        {
          en: 'No',
          es: 'No',
          fr: 'Non',
        },
      ],
      flow: {
        intro: {
          end: {
            id: '',
            item: '3458764528787089953',
          },
        },
        startConnector: '3458764530017430222',
        countryCheck: [
          {
            from: '3458764530037578735',
            to: '3458764530037577979',
            eu: '3458764530037578735',
            non_eu: '3458764530037578841',
          },
          {
            from: '',
            to: '3458764532712221640',
            eu: '3458764530017737553',
            non_eu: '3458764530017737693',
          },
        ],
      },
      navigationLayerId: '3458764532850691994',
    }
  },
  mounted() {
    const countryKey = '_data_rights_survey_country'
    const stateKey = '_data_rights_survey_state'

    const language = localStorage.getItem('_data_rights_survey_language')

    const removeKeyAndRedirect = () => {
      localStorage.removeItem(countryKey)
      localStorage.removeItem(stateKey)

      this.$router.replace({
        hash: this.flow.intro.end.id,
        query:
          language && language !== 'null' && language !== 'undefined'
            ? { language }
            : {},
      })
    }

    const state = localStorage.getItem(stateKey)
    if (state) {
      let confirmMessage =
        'Do you want to continue from where you left off? If you click "Cancel" you will start from the beginning.'

      if (language === 'es') {
        confirmMessage =
          '¿Quieres continuar desde donde te quedaste? Si haces clic en "Cancelar", comenzarás desde el principio.'
      } else if (language === 'fr') {
        confirmMessage =
          'Voulez-vous continuer à partir de là où vous vous êtes arrêté? Si vous cliquez sur "Annuler", vous commencerez depuis le début.'
      }

      if (confirm(confirmMessage)) {
        if (!this.$route.hash) {
          const to = this.flow.countryCheck.find((c) => c.from === '')?.to
          this.redirectOnLanguage(to)
        }

        const country = localStorage.getItem(countryKey)
        this.selectedCountry = country ? JSON.parse(country) : null

        this.state = JSON.parse(state)
        this.$router.replace({
          hash: this.state.path[this.state.path.length - 1].id,
          query: this.$route.query,
        })
      } else {
        removeKeyAndRedirect()
      }
    } else {
      removeKeyAndRedirect()
    }
  },
  watch: {
    '$route.hash'(to, from) {
      let path = [...this.state.path]
      if (!path.map((p) => p.id).includes(to)) {
        path = [
          ...this.state.path,
          {
            id: to,
            content: this.getContent(
              this.getShape(this.currentSlide?.start?.item)
            ),
          },
        ]
      }

      const state = {
        to,
        from,
        path,
      }
      this.state = state
      localStorage.setItem('_data_rights_survey_state', JSON.stringify(state))
      localStorage.setItem(
        '_data_rights_survey_language',
        this.$route.query.language
      )

      this.redirectOnLanguage(to)
    },
  },
  computed: {
    shapes() {
      return this.data.filter((s) => s.type === 'shape')
    },
    images() {
      return this.data.filter((i) => i.type === 'image')
    },
    connectors() {
      return this.data
        .filter((s) => s.type === 'connector' && (s.start || s.end))
        .sort((a) => (a.id === this.flow.startConnector ? -1 : 1))
    },
    navigationItems() {
      return this.data
        .filter((s) => s?.parentId === this.navigationLayerId)
        .sort((a, b) => (a.y > b.y ? 1 : -1))
        .map((s) => {
          return {
            ...s,
            next: this.getNext({ end: { item: s.id } }).map((n) => {
              return {
                ...n,
                shapeParentId: this.getShape(n?.end?.item)?.parentId,
              }
            }),
          }
        })
    },
    currentSlide() {
      if (!this.$route.hash) return this.flow.intro

      return (
        this.connectors.find(
          (slide) => slide.id === this.$route.hash.replace('#', '')
        ) || this.connectors[0]
      )
    },
    sortedCountries() {
      return [...this.countries].sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
  },
  methods: {
    getPrevious(connector) {
      const currentIndex = this.state.path.findIndex((p) =>
        p.id.includes(connector.id)
      )
      const previousHash = this.state.path[currentIndex - 1]?.id
      return typeof previousHash === 'string' ? previousHash : null
    },
    getNext(connector) {
      return this.connectors.filter(
        (c) => c?.start?.item === connector?.end?.item
      )
    },
    getConnector(id) {
      return this.connectors.find((connector) => connector.id === id)
    },
    getShape(id) {
      return this.shapes.find((shape) => shape.id === id)
    },
    getElement(id) {
      return this.data.find((element) => element.id === id)
    },
    stripTags(string) {
      const regex = /(<([^>]+)>)/gi
      return string?.replace(regex, '')
    },
    handleCountrySelect(val) {
      localStorage.setItem('_data_rights_survey_country', JSON.stringify(val))
      const slide = this.flow.countryCheck.find((c) => c.from === '')
      if (val !== null) {
        const nextPage = val.eu === 'true' ? slide.eu : slide.non_eu
        this.$router.replace({ hash: nextPage, query: this.$route.query })
      }
    },
    displayYesOrNo(connector) {
      const content = this.stripTags(
        this.getShape(connector?.end?.item)?.content
      )?.toLowerCase()

      return (
        (content?.startsWith('yes') ||
          content?.toLowerCase().startsWith('no')) &&
        !content?.toLowerCase().startsWith('now') &&
        !content?.toLowerCase().startsWith('non') &&
        !content?.toLowerCase().startsWith('note')
      )
    },
    redirectOnLanguage(to) {
      const slide = this.flow.countryCheck.find(
        (c) => c.to === to.replace('#', '')
      )

      if (slide && this.selectedCountry) {
        if (this.selectedCountry.eu === 'true') {
          this.$router.replace({
            hash: slide.eu,
            query: this.$route.query,
          })
        } else {
          this.$router.replace({
            hash: slide.non_eu,
            query: this.$route.query,
          })
        }
      }
    },
    getContent(item) {
      const convert = (content) => {
        return content
          ?.replace(/&#61;/g, '=')
          ?.replace(/&amp;/g, '&') // Decode HTML entities
          ?.replace(/<p><br(| \/)><\/p>/g, '') // Remove empty breaks
          ?.replace(/href/g, 'target="_blank" href') // Add target blank
          ?.replace(
            /target="_blank" href=["']https:\/\/miro\.com\/app\/board\/[a-zA-Z0-9]+=\/\?moveToWidget=(\d+)&cot=\d+["']/g,
            '' // 'target="_self" href="#$1"'
          ) // Replace miro links with hashes
      }

      const translation = this.translations.find((t) => t.id === item?.id)
      const language = this.$route.query.language
      return convert(
        translation && translation.content[language]
          ? translation.content[language]
          : item?.content
      )
    },
    getImage(connector) {
      return this.getNext(connector)
        .map((n) => this.getElement(n?.end?.item))
        .filter((n) => n && n.type === 'image')
        .map((n) => n.id)
    },
    isActiveNavItem(item) {
      return item.next
        .map((next) => next.shapeParentId)
        .includes(this.getShape(this.currentSlide?.end?.item)?.parentId)
    },
    findString(string) {
      const translation = this.strings.find(
        (t) => t.en.toLowerCase() === this.stripTags(string).toLowerCase()
      )
      const language = this.$route.query.language

      return translation && translation[language]
        ? translation[language]
        : this.stripTags(string)
      // return this.strings.find((t) => t.en === this.stripTags(string))
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Mono:wght@600&display=swap');

:root {
  --red: #d0021b;
  --accent: #d0021b;
  --button: rgba(253, 58, 58, 0.1);
  --button-border: rgba(253, 58, 58, 0.6);
  --fg: black;
  --border: rgba(253, 58, 58, 0.3);
  --bg: #fff1da;
}

.wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  column-gap: 40px;
  height: 100vh;
  overflow: hidden;
}

.navigation__List {
  margin: 0;
  padding: 30px 0;
  height: 100vh;
  overflow-y: auto;
}

.navigation__ListItem {
  display: block;
  color: var(--fg);
  padding: 10px 20px;
}

.navigation__ListItem p {
  all: unset;
}

.navigation__ListItem a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.navigation__ListItem li.is-active a {
  color: var(--accent);
}

.navigation__ListItem.is-active {
  color: var(--accent);
}

.content a[href] {
  color: var(--accent) !important;
  text-decoration: underline;
}

.content a * {
  all: unset;
}

.content a:hover {
  text-decoration: none;
}

.image {
  max-width: 100%;
  margin: 20px auto;
}

.answer {
  opacity: 0.4;
  padding-bottom: 20px;
}

.selectedAnswer {
  color: var(--accent);
  font-style: italic;
  background: var(--button);
  border: 2px dashed var(--border);
  padding: 12px 20px;
  border-radius: 4px;
  font-weight: bold;
}

.cta {
  margin: 20px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.cta__Item {
  margin-bottom: 10px;
  display: block;
}

.cta__Item--Lower {
  order: 1;
}

.cta__Back {
  color: var(--fg);
  display: block;
  padding: 0 0 20px;
  cursor: pointer;
}

.cta__Back:hover {
  color: var(--accent);
}

.cta__Back svg {
  vertical-align: middle;
  fill: currentColor;
  width: 20px;
  height: auto;
}

.cta__Button {
  display: block;
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 4px;
  background-color: var(--accent);
  color: var(--bg);
  font-size: 18px;
}

.cta__Button a {
  color: var(--fg);
  text-decoration: underline;
}

.cta__Button:hover {
  background-color: var(--fg);
  color: var(--bg);
}

.slides {
  all: unset;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.slide {
  height: 100vh;
  overflow-y: auto;
  display: none;
  padding: 30px;
  user-select: none;
  color: var(--fg);
  display: block;
}

.slide *:not(a)[style] {
  all: unset !important;
}

.slide p {
  margin: 10px 0 0;
}

.slide p:first-child {
  margin-top: 0;
}

.slide p br {
  display: none !important;
}

.select {
  padding: 12px 20px;
  border-radius: 4px;
  background-color: var(--button);
  box-shadow: var(--button-border) 0px 0px 0px 1px inset;
  color: var(--accent);
  border: none;
  width: 100%;
  font-size: 18px;
  font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
}

.logo {
  max-width: 300px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
  line-height: 1;
  padding: 0 10px;
}

.logo a {
  color: inherit;
  text-decoration: none;
  display: flex;
}

.logo img {
  max-width: 40px;
  margin-right: 20px;
}

.languages {
  display: flex;
  margin: 0;
  padding: 10px 20px;
  font-family: 'Roboto Mono', monospace;
}

.languages li {
  display: block;
}

.languages a {
  display: block;
  text-transform: uppercase;
  padding: 2px 10px 2px 0;
  font-weight: bold;
  color: var(--accent);
  text-decoration: none;
}

.languages a:hover {
  color: var(--fg);
}

.languages .active {
  color: var(--fg);
}

@media only screen and (max-width: 800px) {
  .wrapper {
    display: block;
    height: auto;
  }

  .navigation__List {
    height: auto;
    padding: 10px 0;
  }

  .slide {
    height: auto;
    padding: 20px 20px 0;
  }
}
</style>
